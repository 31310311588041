<template>
  <wet-card>
    <template #header>

    </template>
    <template #content>
      <stacked-area
        :options="options"
        :aspectRatio="3"
        :chartData="chartData"
        :tooltip-callback="tooltipCallback"
        ref="StackedArea"
      />
    </template>
  </wet-card>
</template>

<script>
import { numToPriceFormat } from '@/common/snippets';
import { mapState } from 'vuex';

export default {
  name: 'cacheFlow',
  components: {
    wetCard: () => import('@/components/ui/wetCardUi.vue'),
    stackedArea: () => import(/* webpackChunkName: "stackedArea" */ '@/components/charts/StackedArea.vue'),
  },
  props: {
    apiData: {
      type: Object,
      default: () => {},
    },
    apiYieldHistory: {
      type: Object,
      default: () => {},
    },
    crops: {
      type: Array,
      default: () => [],
    },
    cropsTitles: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tooltipCallback: {
        label: (tooltipItem, data) => {
          const labelTitle = data.datasets[tooltipItem.datasetIndex].label || '';
          let out = '';

          out += `${labelTitle} ${numToPriceFormat(tooltipItem.yLabel, this.lang)} €`;
          return out;
        },
        footer: (tooltipItem) => {
          let out = '';

          const sum = tooltipItem.reduce((acc, cur) => {
            const accumulator = acc + Number(cur.yLabel);
            return accumulator;
          }, 0);

          out += `${this.droughtRisk.cache_tooltip_sublabel}: ${numToPriceFormat(sum, this.lang)} €`;
          return out;
        },
      },
    };
  },
  computed: {
    ...mapState(['lang', 'strings']),
    colors() {
      return this.strings.drought?.colors || [];
    },
    droughtRisk() {
      return this.strings.droughtRisk || {};
    },
    options() {
      return {};
    },
    labels() {
      // get all years and sort
      const years = Object.values(this.apiYieldHistory)
        .map((cropSet) => cropSet.map((y) => y.year))
        .flat()
        .sort((a, b) => Number(a) - Number(b))
        .reduce((acc, cur) => {
          const accumulator = acc;
          if (cur !== acc[acc.length - 1]) accumulator.push(cur);
          return accumulator;
        }, []);
      return years;
    },
    datasets() {
      const datasets = this.apiData?.crops?.map((crop, i) => ({
        label: this.cropsTitles[crop.type],
        color: this.colors[i]?.color,
        data: this.calcDataset(crop),
      }));
      return datasets || [];
    },
    chartData() {
      return {
        labels: this.labels,
        datasets: this.datasets,
      };
    },
  },
  methods: {
    calcDataset({ type, hectar }) {
      const eurDt = Number(this.crops?.filter((c) => c.id === type)[0]?.eur_dt);
      const dataset = this.labels.map((year) => {
        const yieldValue = Number(this.apiYieldHistory[type]
          ?.filter((d) => Number(d.year) === Number(year))[0]?.yield);
        return yieldValue
          ? Math.round((yieldValue * Number(hectar) * eurDt) / 100) * 100
          : undefined;
      });
      return dataset;
    },
  },
};
</script>

<style>

</style>
